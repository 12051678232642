
:root {
  --loading-bar-width: 6;
  --loading-bar-spacing: 2;
  --loading-bar-min-height: 4;
  --loading-bar-max-height: 50;
}

#bars {
  transform: translate(
    calc(
      (var(--loading-bar-width) * 5 + 9 * var(--loading-bar-spacing)) * -1px
    ),
    0px
  );
  height: 30px;
  left: 50%;
  position: relative;
  top: 50%;
  width: calc(var(--loading-bar-width) * 10px);
}

.bar {
  background: rgb(255,255,255,0.25);
  position: absolute;
  width: calc((var(--loading-bar-width) - 2) * 1px);
  width: calc(var(--loading-bar-width) * 1px);
  border-radius: calc(var(--loading-bar-width) * 0.5px);
  animation: sound 0ms -800ms linear infinite alternate;
}

@keyframes sound {
  0% {
    opacity: 0.35;
    height: calc(var(--loading-bar-min-height) * 1px);
    transform: translate(0, calc(var(--loading-bar-min-height) / -2 * 1px));
  }
  100% {
    opacity: 1;
    height: calc(var(--loading-bar-max-height) * 1px);
    transform: translate(0, calc(var(--loading-bar-max-height) / -2 * 1px));
  }
}

.bar:nth-child(1) {
  left: calc(
    ((var(--loading-bar-width) + 2 * var(--loading-bar-spacing)) * 0+1) * 1px
  );
  /* animation-duration: 474ms; */
}
.bar:nth-child(2) {
  left: calc(
    ((var(--loading-bar-width) + 2 * var(--loading-bar-spacing)) * 1+1) * 1px
  );
  /* animation-duration: 433ms; */
}
.bar:nth-child(3) {
  left: calc(
    ((var(--loading-bar-width) + 2 * var(--loading-bar-spacing)) * 2+1) * 1px
  );
  /* animation-duration: 407ms; */
}
.bar:nth-child(4) {
  left: calc(
    ((var(--loading-bar-width) + 2 * var(--loading-bar-spacing)) * 3+1) * 1px
  );
  /* animation-duration: 458ms; */
}
.bar:nth-child(5) {
  left: calc(
    ((var(--loading-bar-width) + 2 * var(--loading-bar-spacing)) * 4+1) * 1px
  );
  /* animation-duration: 400ms; */
}
.bar:nth-child(6) {
  left: calc(
    ((var(--loading-bar-width) + 2 * var(--loading-bar-spacing)) * 5+1) * 1px
  );
  /* animation-duration: 427ms; */
}
.bar:nth-child(7) {
  left: calc(
    ((var(--loading-bar-width) + 2 * var(--loading-bar-spacing)) * 6+1) * 1px
  );
  /* animation-duration: 441ms; */
}
.bar:nth-child(8) {
  left: calc(
    ((var(--loading-bar-width) + 2 * var(--loading-bar-spacing)) * 7+1) * 1px
  );
  /* animation-duration: 419ms; */
}
.bar:nth-child(9) {
  left: calc(
    ((var(--loading-bar-width) + 2 * var(--loading-bar-spacing)) * 8+1) * 1px
  );
  /* animation-duration: 487ms; */
}
.bar:nth-child(10) {
  left: calc(
    ((var(--loading-bar-width) + 2 * var(--loading-bar-spacing)) * 9+1) * 1px
  );
  animation-duration: 100ms;
}
