.nav-bar__list {
  width: 100%;
  display: flex;
  padding: 0;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding-left: 3em;
  padding-right: 3em;
  padding-top: 1em;
}

.nav-bar__item {
  color: rgb(228, 228, 255);
  opacity: 0.5;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding-right: 20px;
  padding-left: 20px;
  font-size: 1.25em;
  font-weight: 500;
  cursor: pointer;
  height: 1.25em;
  gap: 0.4em;
  transition: 0.3s;
}

.nav-bar__log-in-item {
  opacity: 0.5;
}

.nav-bar__log-in-item img {
  filter: invert(98%) sepia(92%) saturate(1709%) hue-rotate(180deg)
    brightness(104%) contrast(101%);
  opacity: 1;
}

.nav-bar__log-in-img {
  max-height: 100%;
  transition: 0.3s;
}

.nav-bar__item:first-child {
  font-weight: 1000;
  font-size: 1.75em;
  opacity: 1;
  color: white;
}

.nav-bar__item:nth-child(2) {
  flex-grow: 2;
  cursor: default;
}

.nav-bar__item button {
  all: unset;
}

.nav-bar__item:hover {
  color: rgb(210, 210, 255);
}

.nav-bar__log-in-item:hover {
  opacity: 0.45;
}

.nav-bar__item-selected {
  color: white;
}

@media screen and (max-width: 700px) {
  .nav-bar__item {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media screen and (max-width: 500px) {
  .nav-bar__item {
    padding-left: 5px;
    padding-right: 5px;
    font-size: 0.75em;
  }
}

@media screen and (max-width: 500px) {
  .nav-bar__item:first-child {
    font-size: 1em;
  }
}
