.home__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home__title {
  color: white;
  font-size: min(7.5vw, 5em);
  text-shadow: 0 0 20px rgba(255, 255, 255, 0.2),
    0 0 35px rgba(255, 255, 255, 0.125), 0 0 40px rgba(0, 0, 0, 1),
    0 0 50px rgba(0, 0, 0, 1), 0 0 60px rgba(0, 0, 0, 1),
    0 0 80px rgba(0, 0, 0, 1);
  margin-bottom: 0em;
}

.home__subtitle {
  color: rgb(168, 168, 255);
  font-size: min(3.75vw, 2.5em);
  text-shadow: 0 0 20px rgba(255, 255, 255, 0.2),
    0 0 35px rgba(255, 255, 255, 0.125), 0 0 40px rgba(0, 0, 0, 1),
    0 0 50px rgba(0, 0, 0, 1), 0 0 60px rgba(0, 0, 0, 1),
    0 0 80px rgba(0, 0, 0, 1);

  margin-top: 0;
}

.home__grid {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  width: 90%;
  margin: 80 auto;
  /* border: 2px solid white; */
}

.home__text,
.home__image {
  grid-row: 1;
}

.home__image {
  z-index: -1;
  mask-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.05) 100%
  );
  /* filter: brightness(1.75) drop-shadow(0 0 2em rgba(175, 175, 255, 0.2)); */
  filter: brightness(1.75);
  width: 100%;
  padding: 5;
  max-width: 80vh;
}

.home__image-right {
  grid-column: 6 / -1;
  justify-self: end;
}

.home__image-left {
  grid-column: 1 / 6;
  justify-self: start;
}

.home__text-left {
  grid-column: 1 / 7;
}

.home__text-right {
  grid-column: 4 / -1;
}

.home__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
