@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  box-sizing: border-box;
}

html {
  font-family: "Roboto", sans-serif;
}

body {
  margin: 0;
  background-image: linear-gradient(rgb(5, 5, 50), rgb(1, 1, 10));
  background-color: rgb(2, 2, 25);
  background-attachment: fixed;
  background-size: 100%;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.1);
}

.gradient-background {
  background: radial-gradient(
    rgba(255, 255, 255, 0.025),
    rgba(255, 255, 255, 0)
  );
}

.side-by-side {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100%;
  padding: 1rem;
  gap: 1em;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  flex-grow: 2;
  flex-shrink: 2;
  width: 40vh;
  height: 100%;
  gap: 1rem;
}

.title {
  width: 100%;
  text-align: center;
  color: white;
}

.faq-style-wrapper {
  margin-top: 2em;
}

.faq-style-wrapper .faq-row-wrapper .faq-title {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.faq-style-wrapper .faq-body .faq-row {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.faq-style-wrapper .faq-body .faq-row .row-title {
  font-size: larger;
  color: grey;
}
